import React from "react";
import "./NavBar.css";
import { NavLink } from "react-router-dom";

function LinkBar() {
  return (
    <div className="LinksBar">
      <ul className="LinkList">
        <li>
          <NavLink className="NavBarLink" exact to="/" activeClassName="active">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink className="NavBarLink" to="/resume" activeClassName="active">
            Resume
          </NavLink>
        </li>
        <li>
          <NavLink
            className="NavBarLink"
            to="/portfolio"
            activeClassName="active"
          >
            Portfolio
          </NavLink>
        </li>
        <li>
          <NavLink
            className="NavBarLink"
            to="/contact"
            activeClassName="active"
          >
            Contact
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default LinkBar;
