// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PortfolioPageBody {
  font-family: var(--roboto);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 25px;
  animation-name: fade-in;
  animation-duration: 1s;
}

.PortfolioPageLinks {
  display: flex;
  flex-direction: row;
}

#InstagramIcon {
  height: 100%;
  padding-left: 5px;
}

.ProjectsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 80%;
  padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/portfolioPage/PortfolioPage.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,mBAAmB;EACnB,uBAAuB;EACvB,UAAU;EACV,YAAY;AACd","sourcesContent":[".PortfolioPageBody {\n  font-family: var(--roboto);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  margin: 25px;\n  animation-name: fade-in;\n  animation-duration: 1s;\n}\n\n.PortfolioPageLinks {\n  display: flex;\n  flex-direction: row;\n}\n\n#InstagramIcon {\n  height: 100%;\n  padding-left: 5px;\n}\n\n.ProjectsList {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: center;\n  width: 80%;\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
