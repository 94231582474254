import ResumeScreen from "./resume/Resume";
import HomeScreen from "./home/HomeScreen";
import PortfolioScreen from "./portfolio/PortfolioScreen";
import ContactScreen from "./contact/Contact";
import NotFoundScreen from "./notFound/NotFound";

export {
  ResumeScreen,
  HomeScreen,
  PortfolioScreen,
  ContactScreen,
  NotFoundScreen,
};
