import React from "react";
import "./PortfolioPage.css";
import ProjectThumb from "../projectThumb/ProjectThumb";
import {
  MazeImg,
  TowerImg,
  GamesImage,
  PokemonImage,
  DatingImg,
  DirwatcherImg,
  PhotosImg,
  RecipeImg,
  TwitterImg,
  TodoImg,
} from "../../images/imageExport";

function PortfolioPage() {
  return (
    <div className="PortfolioPageBody">
      <h1>My Portfolio</h1>
      <div className="ProjectsList">
        {/* Temp Remove until fix API */}
        {/* <ProjectThumb
          link={"https://kwitter-query.vercel.app/"}
          title={"Kwitter"}
          imageURL={KwitterImage}
          description={
            "Group project, goal to recreate a Twitter like social media platform"
          }
        />
        <br /> */}
        <ProjectThumb
          link={"https://jeffsimp88.github.io/maze/"}
          title={"Maze"}
          imageURL={MazeImg}
          description={
            "Created a functional maze using only Javascript, HTML, and CSS. Completed after only 2 months of learning coding."
          }
        />
        <br />
        <ProjectThumb
          link={"https://github.com/jeffsimp88/recipes_book"}
          title={"Recipe Box"}
          imageURL={RecipeImg}
          description={
            "Django site for recipes and authors. My earliest usage of Django. Can add, edit, or favorite recipes."
          }
        />
        <br />
        <ProjectThumb
          link={"https://jeffsimp88.github.io/pokemon-api/"}
          title={'"Who\'s That Pokémon" App'}
          imageURL={PokemonImage}
          description={
            "A quick app I created to demonstrate to students how HTML, CSS, and JavaScript can be used with an API."
          }
        />
        <br />
        <ProjectThumb
          link={"https://github.com/jeffsimp88/twitterclone"}
          title={"Twitter Clone"}
          imageURL={TwitterImg}
          description={
            "Twitter clone site using Django. User may create user, post tweet, tag other users, follow users."
          }
        />
        <br />
        <ProjectThumb
          link={"https://the-dating-scene.vercel.app/"}
          title={"The Dating Scene"}
          imageURL={DatingImg}
          description={
            "Group project, in 2 weeks we created from scratch a social media platform. I made the design of the site, profiles, and the chat system for each room."
          }
        />
        <br />
        <ProjectThumb
          link={"https://repl.it/@JeffreySimpson/dirwatcher"}
          title={"Python Dirwatcher"}
          imageURL={DirwatcherImg}
          description={
            "A python long running program monitors a given directory for any file changes."
          }
        />
        <br />
        <ProjectThumb
          link={"https://jeffsimp88.github.io/tower-of-hanoi/"}
          title={"Tower of Hanoi"}
          imageURL={TowerImg}
          description={
            "After less than 2 months of intro to coding. Worked with partner, made a working Tower of Hanoi application in 2 days."
          }
        />
        <br />
        <ProjectThumb
          link={"https://jeffsimp88.github.io/todo_app/"}
          title={"Todo App"}
          imageURL={TodoImg}
          description={"Todo application using React & Redux."}
        />
        <br />
        <ProjectThumb
          link={"https://jeffsimp88.github.io/photos-from-here/"}
          title={"Photos from Here"}
          imageURL={PhotosImg}
          description={
            "Main focus of project to demonstrate the ability to make API request from a source of photos based on the user's location sharing."
          }
        />
        <br />
        <ProjectThumb
          link={"https://jeffsimp88.github.io/game_of_chance/"}
          title={"Game of Chance"}
          imageURL={GamesImage}
          description={"A mini game application, made in less than a day."}
        />
      </div>
    </div>
  );
}

export default PortfolioPage;
