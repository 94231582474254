// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --green: rgb(136, 185, 136);
  --gray: rgb(95, 95, 98);
  --light-gray: rgb(153, 153, 153);
  --dark-gray: rgb(42, 42, 42);
  --white: rgb(245, 245, 245);
  --black: rgb(0, 0, 0);
  --roboto: "Roboto", sans-serif;
  --overpass: "Overpass", sans-serif;
}

body {
  align-items: center;
  background-color: var(--dark-gray);
  color: var(--white);
  font-family: var(--roboto);
}

a {
  color: var(--green);
}

.Button {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 230px;
  color: var(--green);
  border: solid 1px var(--white);
  border-radius: 1%;
  padding: 8px 3px 8px 3px;
  transition-duration: 0.4s;
}

.Button:hover {
  color: var(--dark-gray);
  background-color: var(--white);
  box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.123);
}
#PDFIcon {
  height: 100%;
  padding-left: 5px;
  transition-duration: 0.3s;
}
.Button:hover > #PDFIcon {
  filter: invert(100%);
}

@keyframes fade-in {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AACA;EACE,2BAA2B;EAC3B,uBAAuB;EACvB,gCAAgC;EAChC,4BAA4B;EAC5B,2BAA2B;EAC3B,qBAAqB;EACrB,8BAA8B;EAC9B,kCAAkC;AACpC;;AAEA;EACE,mBAAmB;EACnB,kCAAkC;EAClC,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,8BAA8B;EAC9B,iBAAiB;EACjB,wBAAwB;EACxB,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;EACvB,8BAA8B;EAC9B,gDAAgD;AAClD;AACA;EACE,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;AAC3B;AACA;EACE,oBAAoB;AACtB;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,aAAa;EACf;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap\");\n:root {\n  --green: rgb(136, 185, 136);\n  --gray: rgb(95, 95, 98);\n  --light-gray: rgb(153, 153, 153);\n  --dark-gray: rgb(42, 42, 42);\n  --white: rgb(245, 245, 245);\n  --black: rgb(0, 0, 0);\n  --roboto: \"Roboto\", sans-serif;\n  --overpass: \"Overpass\", sans-serif;\n}\n\nbody {\n  align-items: center;\n  background-color: var(--dark-gray);\n  color: var(--white);\n  font-family: var(--roboto);\n}\n\na {\n  color: var(--green);\n}\n\n.Button {\n  text-decoration: none;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 30px;\n  width: 230px;\n  color: var(--green);\n  border: solid 1px var(--white);\n  border-radius: 1%;\n  padding: 8px 3px 8px 3px;\n  transition-duration: 0.4s;\n}\n\n.Button:hover {\n  color: var(--dark-gray);\n  background-color: var(--white);\n  box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.123);\n}\n#PDFIcon {\n  height: 100%;\n  padding-left: 5px;\n  transition-duration: 0.3s;\n}\n.Button:hover > #PDFIcon {\n  filter: invert(100%);\n}\n\n@keyframes fade-in {\n  from {\n    opacity: 0%;\n  }\n  to {\n    opacity: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
