import React from "react";
import "./ProjectThumb.css";

function ProjectThumb(props) {
  const { title, imageURL, description, link } = props;
  return (
    <a
      href={link}
      className="ProjectThumbTitle ProjectThumbBox"
      target="_blank"
      rel="noreferrer"
    >
      {title}
      <br />
      <img className="ProjectThumbImage" src={imageURL} alt={title} />
      <p className="ProjectThumbDescription">{description}</p>
    </a>
  );
}

export default ProjectThumb;
