import React from "react";
import NavBar from "../../components/navBar/NavBar";
import LinkBar from "../../components/navBar/LinkBar";
import ResumePage from "../../components/resumePage/ResumePage";
import Footer from "../../components/footer/Footer.js";
import "./Resume.css";

function ResumeScreen() {
  return (
    <>
      <NavBar />
      <LinkBar />
      <ResumePage />
      <Footer />
    </>
  );
}

export default ResumeScreen;
