import MazeImg from "./projectImages/maze.jpg";
import TowerImg from "./projectImages/towerHanoi.jpg";
import GamesImage from "./projectImages/gamesOfChance.jpg";
import PokemonImage from "./projectImages/pokemon.jpg";
import KwitterImage from "./projectImages/kwitter.jpg";
import DatingImg from "./projectImages/datingScene.jpg";
import DirwatcherImg from "./projectImages/dirwatcher.jpg";
import PhotosImg from "./projectImages/photosFromHere.jpg";
import RecipeImg from "./projectImages/recipeBox.jpg";
import TwitterImg from "./projectImages/twitterClone.jpg";
import TodoImg from "./projectImages/todoApp.jpg";

import ProfileImage from "./aboutImages/jeffrey_simpson_profile.jpg";
import FrontCertificate from "./aboutImages/front_end_cert.png";
import FullCertificate from "./aboutImages/full_stack_cert.png";

import PDFIcon from "./iconImages/PDF.png";
import KenzieIcon from "./iconImages/kenzie.png";
import InstagramIcon from "./iconImages/instagram.png";
import LinkedInIcon from "./iconImages/linkedIn.png";
import GitHubIcon from "./iconImages/GitHubWhite.png";

//Icon Images export
export {
    InstagramIcon,
    LinkedInIcon,
    GitHubIcon,
    PDFIcon,
    KenzieIcon,
}

// Resume Images export
export {
    ProfileImage,
    FrontCertificate,
    FullCertificate,
}

// Portfolio Images export
export {
    MazeImg,
    TowerImg,
    GamesImage,
    PokemonImage,
    KwitterImage,
    DatingImg,
    DirwatcherImg,
    PhotosImg,
    RecipeImg,
    TwitterImg,
    TodoImg,
}
