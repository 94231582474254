import React from "react";
import { Link } from "react-router-dom";
import {
  LinkedInIcon,
  GitHubIcon,
  InstagramIcon,
} from "../../images/imageExport";

import "./NavBar.css";

function NavBar() {
  return (
    <>
      <div className="NavBar">
        <div className="NavBarHeader">
          <Link className="NavHeader" to="/">
            | Jeffrey Simpson
          </Link>
          <div>
            <a
              className="NavBarProfileImg"
              href="https://www.linkedin.com/in/jeffrey-simpson-61834560"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="NavBarThumb"
                src={LinkedInIcon}
                alt="LinkedIn Profile"
                title="LinkedIn Profile"
              />
            </a>
            <a
              className="NavBarProfileImg"
              href="https://github.com/jeffsimp88"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="NavBarThumb"
                src={GitHubIcon}
                alt="GitHub Profile"
                title="GitHub Profile"
              />
            </a>
            <a
              className="NavBarProfileImg"
              href="https://www.instagram.com/geoffer86/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="NavBarThumb"
                src={InstagramIcon}
                alt="Instagram Profile"
                title="Instagram Profile"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavBar;
