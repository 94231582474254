import React from "react";
import NotFoundPage from "../../components/notFoundPage/NotFoundPage";
import "./NotFound.css";

function NotFoundScreen() {
  return (
    <>
      <NotFoundPage />
    </>
  );
}

export default NotFoundScreen;
