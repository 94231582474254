import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import {
  ResumeScreen,
  HomeScreen,
  PortfolioScreen,
  ContactScreen,
  NotFoundScreen,
} from "../../screens/index";

function Navigation() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={HomeScreen} />
        <Route exact path="/resume" component={ResumeScreen} />
        <Route exact path="/portfolio" component={PortfolioScreen} />
        <Route exact path="/contact" component={ContactScreen} />
        <Route path="*" component={NotFoundScreen} />
      </Switch>
    </BrowserRouter>
  );
}

export default Navigation;
