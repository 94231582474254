import React, {useState} from "react";
import "./ContactPage.css";
import LinkedInIcon from "../../images/iconImages/linkedIn.png";
import emailjs from "emailjs-com"

function ContactPage() {
  const [form, setForm] = useState({
    user_name: "",
    user_email: "",
    subject: "",
    message: "",
    isSent: false,
  })

  const [isSent, setSent] = useState("")

  function handleChange(e) {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    setForm((prevState) => ({ ...prevState, [inputName]: inputValue }));
  }

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_1poef85', 'contact_form', e.target, 'user_9mkoNFWLjimTgPvBLN9Jd')
      .then((result) => {
          console.log(result.text, "Your message was sent.");
          setSent("Sent")
        }, (error) => {
          console.log(error.text);
          setSent("Error")
      });

    setForm({
      user_name: "",
      user_email: "",
      subject: "",
      message: "",
    })
  }
  return (
    <div className="ContactPageBody">
      <div>
        <h1 className="ContactPageH1">Contact Me</h1>
        <p id="ContactPageP">
          <strong>Jeffrey Simpson</strong>
        </p>
      <a
        className="Button"
        href="https://www.linkedin.com/in/jeffrey-simpson-61834560"
        target="_blank"
        rel="noreferrer"
      >
        Find me on LinkedIn{" "}
        <img src={LinkedInIcon} alt="LinkedIn Icon" id="LinkedInIcon" />
      </a>
        <p><strong>Send me a message:</strong></p>
        <form className="ContactPageForm" onSubmit={sendEmail}>
          <input type="hidden" name="contact_number" />
            <input
              id="ContactPageInput"
              type="text" 
              name="user_name" 
              placeholder="Name*"
              onChange={handleChange}
              value={form.user_name}
              required 
            />
            <input
              id="ContactPageInput"
              type="email" 
              title="Please enter a valid email address."
              name="user_email" 
              placeholder="E-Mail*"
              onChange={handleChange}
              value={form.user_email}
              required 
            />
            <input
              id="ContactPageInput"
              type="text" 
              name="subject" 
              placeholder="Subject"
              onChange={handleChange}
              value={form.subject} 
            />
            <textarea
              id="ContactPageTextArea"
              name="message"
              maxLength="300" 
              placeholder="Type your message here...*"
              onChange={handleChange}
              value={form.message}
              cols="50"
              rows="6"
              required
              />
          <input type="submit" value="Send" id="ContactPageFormSubmit"/>
          {isSent === "Sent" &&
          <p className="ContagePageP">Message sent sucessfully.</p>
          }
          {isSent === "Error" &&
          <p className="ContagePageP">Sorry, there was an error sending your message.</p>
          }
          <p id="ContactPageFormP">*required</p>
      </form>
      </div>
    </div>
  );
}

export default ContactPage;
