import React, { useState } from "react";
import "./ResumePage.css";
import ResumeFile from "../../files/Jeffrey Simpson's Resume.pdf";
import AboutPage from "../aboutPage/AboutPage"
import {
  PDFIcon,
  KenzieIcon,
  FrontCertificate,
  FullCertificate,
} from "../../images/imageExport";

function ResumePage() {
  const [imageOpacity, setOpacity] = useState({
    frontCert: { opacity: "0%", visibility: "hidden" },
    fullCert: { opacity: "0%", visibility: "hidden" },
  });
  function showFrontCert() {
    if (imageOpacity.frontCert.visibility === "visible") {
      setOpacity({
        ...imageOpacity,
        frontCert: { opacity: "0%", visibility: "hidden" },
      });
    } else {
      setOpacity({
        ...imageOpacity,
        frontCert: { opacity: "100%", visibility: "visible" },
      });
    }
  }

  function showFullCert() {
    if (imageOpacity.fullCert.visibility === "visible") {
      setOpacity({
        ...imageOpacity,
        fullCert: { opacity: "0%", visibility: "hidden" },
      });
    } else {
      setOpacity({
        ...imageOpacity,
        fullCert: { opacity: "100%", visibility: "visible" },
      });
    }
  }

  return (
    <div className="ResumePageBody">
      <div className="ResumePageImgBackground" style={imageOpacity.frontCert}>
        <span className="ResumePageClose" onClick={showFrontCert}>
          &times;
        </span>
        <img
          onClick={showFrontCert}
          style={imageOpacity.frontCert}
          className="ResumePageCertImg"
          src={FrontCertificate}
          alt="Front-End Certificate"
        />
      </div>
      <div className="ResumePageImgBackground" style={imageOpacity.fullCert}>
        <span className="ResumePageClose" onClick={showFullCert}>
          &times;
        </span>
        <img
          onClick={showFullCert}
          style={imageOpacity.fullCert}
          className="ResumePageCertImg"
          src={FullCertificate}
          alt="Front-End Certificate"
        />
      </div>
      <div className="ResumePageButtons">
        <a
          className="Button"
          href={ResumeFile}
          target="_blank"
          rel="noreferrer"
        >
          Jeffrey's Resume PDF <img src={PDFIcon} alt="PDF Icon" id="PDFIcon" />
        </a>
        <br />
        <p
          className="Button"
          style={{ cursor: "pointer" }}
          onClick={showFrontCert}
        >
          Front-End Certificate
          <img src={KenzieIcon} alt="Kenzie Academy Icon" id="PDFIcon" />
        </p>
        <br />
        <p
          className="Button"
          style={{ cursor: "pointer" }}
          onClick={showFullCert}
        >
          Full Stack Certificate
          <img src={KenzieIcon} alt="Kenzie Academy Icon" id="PDFIcon" />
        </p>
      </div>
      <AboutPage />
      <div className="ResumePageTextBody">
        <div className="ResumePageSideText">
          <h3>
            <span style={{ textDecoration: "underline" }}>
              Additional Qualifications
            </span>
          </h3>
          <h4>Programming Languages</h4>
          <ul>
            <li>Python</li>
            <li>JavaScript</li>
            <li>TypeScript</li>
            <li>HTML5</li>
            <li>CSS</li>
            <li>Ruby</li>
            <li>Java</li>
            <li>NodeJS</li>
          </ul>
          <h4>Libraries/Frameworks</h4>
          <ul>
            <li>Django</li>
            <li>React</li>
            <li>MongoDB</li>
            <li>Firebase</li>
            <li>Postgres</li>
            <li>SQLite3</li>
            <li>Ruby on Rails</li>
          </ul>
          <h4>Software</h4>
          <ul>
            <li>
              JetBrains:{" "}
              <ul>
                <li>WebStorm</li>
                <li>RubyMine</li>
                <li>PyCharm</li>
              </ul>
            </li>
            <li>VS Code</li>
            <li>Windows/Ubuntu Terminal</li>
            <li>GitHub</li>
          </ul>
        </div>
        <div className="ResumePageText">
          <h2>
            <span style={{ textDecoration: "underline" }}>
              <strong>Work Experience</strong>
            </span>
          </h2>
          <h3>&nbsp;</h3>
          <h4>
            DMI (
            <a
              className="ResumeLink"
              href="https://dminc.com/"
              target="_blank"
              rel="noreferrer"
            >
              Digital Management, LLC
            </a>
            )
          </h4>
          <p>Associate Software Engineer:&nbsp; May 2022 &ndash; present</p>
          <ul>
            <li>
              <p>Work on various applications for clients.</p>
            </li>
            <li>
              <p>Meet daily with developer teams to discuss project status.</p>
            </li>
            <li>
              <p>
                Take on different coding tasks to add or refactor existing
                legacy applications.
              </p>
            </li>
            <li>
              <p>Meet with clients for updates on my task.</p>
            </li>
            <li>
              <p>Report to Application Architect.</p>
            </li>
            <li>
              <p>
                Pair with other developers on my team to accomplish shared task.
              </p>
            </li>
            <li>
              <p>
                Demonstrate experience with a variety of coding languages and
                frameworks (i.e TypeScript, Ruby, JavaScript, React, Node.js)
              </p>
            </li>
            <li>
              <p>
                Participate in planning meetings with my team to refine project
                tickets.
              </p>
            </li>
          </ul>
          <p>&nbsp;</p>

          <h4>Kenzie Academy</h4>

          <p>
            Software Engineer Facilitator:&nbsp; May 2021 &ndash; April 2022
          </p>
          <ul>
            <li>
              <p>Review and grade assessments and code submitted by students</p>
            </li>
            <li>
              <p>
                Responsible for student support in the Software Engineering
                program
              </p>
            </li>
            <li>
              <p>
                Provide additional coding instruction support to students with
                assessments, projects, and questions on program material
              </p>
            </li>
            <li>
              <p>Prepare lesson plans based on curriculum</p>
            </li>
            <li>
              <p>
                Report to lead facilitators and instructors to ensure consistent
                student success
              </p>
            </li>
          </ul>
          <br />
          <p>
            Software Engineering Student:&nbsp; April 2020 &ndash; April 2021
          </p>
          <ul>
            <li>
              <p>
                Studied various coding languages such as JavaScript, Python,
                HTML, CSS
              </p>
            </li>
            <li>
              <p>Developed projects demonstrating new coding techniques</p>
            </li>
            <li>
              <p>Worked on group projects on large scale applications</p>
            </li>
            <li>
              <p>Assisted students with errors on their projects</p>
            </li>
            <li>
              <p>Demonstrated coding abilities to faculty</p>
            </li>
            <li>
              <p>Performed code reviews on previous code or on peers' code</p>
            </li>
          </ul>
          <p>&nbsp;</p>
          <h4>Podcast - Tudor, I Hardly Know Her</h4>
          <p>
            Sound Designer, Producer&nbsp; Co-Host, Editor:&nbsp;&nbsp;October
            2016 &ndash; October 2019
          </p>
          <ul>
            <li>
              <p>
                Set up audio equipment and maintain quality throughout weekly
                recordings
              </p>
            </li>
            <li>
              <p>
                Edit audio tracks to ensure consistent audio levels, cut errors
                and lower&nbsp;background noise
              </p>
            </li>
            <li>
              <p>Publish final audio and manage hosting platform via libsyn</p>
            </li>
            <li>
              <p>
                Market podcast and monitor social media presence on Facebook and
                Twitter
              </p>
            </li>
            <li>
              <p>
                Production stats: 100+ episodes produced, over 275,000 lifetime
                downloads
              </p>
            </li>
          </ul>
          <p>&nbsp;</p>
          <h4>GEICO</h4>
          <p>Telephone Claims Representative: August 2018 &ndash; March 2019</p>
          <ul>
            <li>
              <p>Managed average of 20 client claims at a time</p>
            </li>
            <li>
              <p>
                Proactively communicated with clients via phone and email to
                inform of claim status
              </p>
            </li>
            <li>
              <p>
                Investigated and documented claim incidents to determine status
              </p>
            </li>
            <li>
              <p>Advocated for clients in disputed claims</p>
            </li>
            <li>
              <p>Specialized in GEICO-exclusive claims software, Atlas</p>
            </li>
            <li>
              <p>Worked closely with supervisor and team to cover overflow</p>
            </li>
          </ul>
          <p>&nbsp;</p>
          <h4>The Exchange</h4>
          <p>Manager: April 2013-June 2015, October 2017 &ndash; August 2018</p>
          <ul>
            <li>
              <p>Managed stores and employees</p>
            </li>
            <li>
              <p>Responsible for opening and closing cash drawers</p>
            </li>
            <li>
              <p>Provided expert knowledge on films and games to customers</p>
            </li>
            <li>
              <p>Organized and catalogued 100,000+ in-stock films and games</p>
            </li>
            <li>
              <p>Managed film, game, music, and gaming system trade-ins</p>
            </li>
            <li>
              <p>Maintained active knowledge of movie and gaming industries</p>
            </li>
          </ul>
          <p>&nbsp;</p>
          <h3>
            <span style={{ textDecoration: "underline" }}>Education</span>
          </h3>
          <p>Kenzie Academy&nbsp;</p>
          <ul>
            <li>
              <p>Full Stack Web Development</p>
            </li>
            <li>
              <p>Graduated April 2021</p>
            </li>
          </ul>
          <p>Ball State University&nbsp;</p>
          <ul>
            <li>
              <p>Bachelor of Arts Degree in Telecommunications</p>
            </li>
            <li>
              <p>Graduated July 2011</p>
            </li>
            <li>
              <p>3.0/4.0 GPA</p>
            </li>
          </ul>
          <p>Carmel High School</p>
          <ul>
            <li>
              <p>Graduated June 2007</p>
            </li>
            <li>
              <p>3.0/4.0 GPA</p>
            </li>
          </ul>

          <p>&nbsp;</p>
        </div>
      </div>
    </div>
  );
}

export default ResumePage;
