import React from "react";
import "./HomePage.css";
import AboutPage from "../aboutPage/AboutPage";
import { Link } from "react-router-dom";

function HomePage() {
  return (
    <>
      <div className="HomeBody">
        <div className="HomePageCenter">
          <Link className="HomePageButtons" to="/resume">
            Resume
          </Link>
          <div className="HomePageWelcome">
            <h1 className="HomePageHeader1">WELCOME</h1>
            <h5 className="HomePageHeader5">coding. developing. creating.</h5>
          </div>
          <Link className="HomePageButtons" to="/portfolio">
            Portfolio
          </Link>
        </div>
        <div>
          <Link className="HomePageButtons" to="/contact">
            Contact Me
          </Link>
        </div>
      </div>
      <div className="AboutHome">
        {" "}
        <AboutPage style={{ margin: "0" }} page={"HomeButton"} />
      </div>
    </>
  );
}

export default HomePage;
