import "./App.css";
import Navigation from "./components/navigation/Navigation";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Jeffrey D. Simpson</title>
        </Helmet>
        <Navigation />
      </HelmetProvider>
    </div>
  );
}

export default App;
