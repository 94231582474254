import React from "react";
import "./HomeScreen.js";
import HomePage from "../../components/homePage/HomePage";
import NavBar from "../../components/navBar/NavBar.js";
import Footer from "../../components/footer/Footer.js";

function HomeScreen() {
  return (
    <>
      <NavBar />
      <HomePage />
      <Footer />
    </>
  );
}

export default HomeScreen;
