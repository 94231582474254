// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectThumbBox {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--roboto);
  color: var(--white);
  border: 1px solid var(--white);
  transition-duration: 0.3s;
  height: 375px;
  width: 328px;
  margin: 5px;
  padding: 10px;
}
.ProjectThumbBox:hover {
  background-color: var(--white);
}
.ProjectThumbTitle {
  font-weight: 500;
  color: var(--white);
  text-decoration: none;
  text-align: center;
  margin: 10px;
  transition-duration: 0.3s;
}

.ProjectThumbTitle:hover {
  color: var(--dark-gray);
}

.ProjectThumbDescription {
  font-weight: 300;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0px 0px 10px 0px;
  text-align: left;
  white-space: pre-wrap;
}
.ProjectThumbImage {
  box-sizing: border-box;
  height: 225px;
  width: initial;
  margin: 5px;
  transition-duration: 0.2s;
}

.ProjectsPageThumb:hover {
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/components/projectThumb/ProjectThumb.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,0BAA0B;EAC1B,mBAAmB;EACnB,8BAA8B;EAC9B,yBAAyB;EACzB,aAAa;EACb,YAAY;EACZ,WAAW;EACX,aAAa;AACf;AACA;EACE,8BAA8B;AAChC;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;EACrB,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,uBAAkB;EAAlB,kBAAkB;EAClB,wBAAwB;EACxB,gBAAgB;EAChB,qBAAqB;AACvB;AACA;EACE,sBAAsB;EACtB,aAAa;EACb,cAAc;EACd,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,+CAA+C;AACjD","sourcesContent":[".ProjectThumbBox {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  font-family: var(--roboto);\n  color: var(--white);\n  border: 1px solid var(--white);\n  transition-duration: 0.3s;\n  height: 375px;\n  width: 328px;\n  margin: 5px;\n  padding: 10px;\n}\n.ProjectThumbBox:hover {\n  background-color: var(--white);\n}\n.ProjectThumbTitle {\n  font-weight: 500;\n  color: var(--white);\n  text-decoration: none;\n  text-align: center;\n  margin: 10px;\n  transition-duration: 0.3s;\n}\n\n.ProjectThumbTitle:hover {\n  color: var(--dark-gray);\n}\n\n.ProjectThumbDescription {\n  font-weight: 300;\n  width: fit-content;\n  margin: 0px 0px 10px 0px;\n  text-align: left;\n  white-space: pre-wrap;\n}\n.ProjectThumbImage {\n  box-sizing: border-box;\n  height: 225px;\n  width: initial;\n  margin: 5px;\n  transition-duration: 0.2s;\n}\n\n.ProjectsPageThumb:hover {\n  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
