import React from "react";
import NavBar from "../../components/navBar/NavBar";
import LinkBar from "../../components/navBar/LinkBar";
import ContactPage from "../../components/contactPage/ContactPage";
import Footer from "../../components/footer/Footer.js";
import "./Contact.css";

function ContactScreen() {
  return (
    <>
      <NavBar />
      <LinkBar />
      <ContactPage />
      <Footer />
    </>
  );
}

export default ContactScreen;
