import React from "react";
import "./PortfolioScreen.css";
import NavBar from "../../components/navBar/NavBar";
import LinkBar from "../../components/navBar/LinkBar";
import PorfolioPage from "../../components/portfolioPage/PortfolioPage";
import Footer from "../../components/footer/Footer.js";

function PortfolioScreen() {
  return (
    <>
      <NavBar />
      <LinkBar />
      <PorfolioPage />
      <Footer />
    </>
  );
}

export default PortfolioScreen;
