// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerBody {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  bottom: 0;
  background-color: rgba(95, 95, 98, 0.4);
  padding: 5px 30px 5px 30px;
  height: -moz-fit-content;
  height: fit-content;
  width: 99%;
}

.footerText {
  font-size: small;
  font-weight: 200;
  color: var(--white);
  padding: 0px 30px 0px 0px;
  opacity: 0.5;
  margin: 0;
}

.FooterThumb {
  height: 30px;
}
.FooterProfileImg {
  margin: 0px 5px 0px 5px;
  opacity: 0.5;
  transition-duration: 0.3s;
}

.FooterProfileImg:hover {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,qBAAqB;EACrB,WAAW;EACX,SAAS;EACT,uCAAuC;EACvC,0BAA0B;EAC1B,wBAAmB;EAAnB,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,YAAY;AACd;AACA;EACE,uBAAuB;EACvB,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".footerBody {\n  position: fixed;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: flex-end;\n  width: 100%;\n  bottom: 0;\n  background-color: rgba(95, 95, 98, 0.4);\n  padding: 5px 30px 5px 30px;\n  height: fit-content;\n  width: 99%;\n}\n\n.footerText {\n  font-size: small;\n  font-weight: 200;\n  color: var(--white);\n  padding: 0px 30px 0px 0px;\n  opacity: 0.5;\n  margin: 0;\n}\n\n.FooterThumb {\n  height: 30px;\n}\n.FooterProfileImg {\n  margin: 0px 5px 0px 5px;\n  opacity: 0.5;\n  transition-duration: 0.3s;\n}\n\n.FooterProfileImg:hover {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
