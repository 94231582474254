// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AboutPageBody {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 25px 25px 50px 25px;
  animation-name: fade-in;
  animation-duration: 1s;
}

.AboutPageTextbox {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.AboutPageP1 {
  width: 500px;
}

#AboutPageP {
  font-weight: 300;
}

.AboutPageImgBox {
  background-color: var(--gray);
  border-radius: 3%;
  overflow: hidden;
  margin: 5px;
  height: 419px;
}

.AboutPageImg {
  height: 420px;
}
#LinkedInIcon {
  transition-duration: 0.3s;
}
.Button:hover > #LinkedInIcon {
  filter: invert(100%);
}
#InstagramIcon {
  height: 100%;
  padding-left: 5px;
  transition-duration: 0.3s;
}
.Button:hover > #InstagramIcon {
  filter: invert(100%);
}

#GitHubIcon {
  height: 100%;
  padding-left: 5px;
  transition-duration: 0.3s;
}

.Button:hover > #GitHubIcon {
  filter: invert(100%);
}
`, "",{"version":3,"sources":["webpack://./src/components/aboutPage/AboutPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,mBAAmB;EACnB,4BAA4B;EAC5B,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,aAAa;AACf;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,oBAAoB;AACtB;AACA;EACE,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;AAC3B;AACA;EACE,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".AboutPageBody {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-direction: row;\n  padding: 25px 25px 50px 25px;\n  animation-name: fade-in;\n  animation-duration: 1s;\n}\n\n.AboutPageTextbox {\n  display: flex;\n  flex-direction: column;\n  margin: 10px;\n}\n.AboutPageP1 {\n  width: 500px;\n}\n\n#AboutPageP {\n  font-weight: 300;\n}\n\n.AboutPageImgBox {\n  background-color: var(--gray);\n  border-radius: 3%;\n  overflow: hidden;\n  margin: 5px;\n  height: 419px;\n}\n\n.AboutPageImg {\n  height: 420px;\n}\n#LinkedInIcon {\n  transition-duration: 0.3s;\n}\n.Button:hover > #LinkedInIcon {\n  filter: invert(100%);\n}\n#InstagramIcon {\n  height: 100%;\n  padding-left: 5px;\n  transition-duration: 0.3s;\n}\n.Button:hover > #InstagramIcon {\n  filter: invert(100%);\n}\n\n#GitHubIcon {\n  height: 100%;\n  padding-left: 5px;\n  transition-duration: 0.3s;\n}\n\n.Button:hover > #GitHubIcon {\n  filter: invert(100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
