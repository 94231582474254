import React from "react";
import "./Footer.css";
import {
  LinkedInIcon,
  GitHubIcon,
  InstagramIcon,
} from "../../images/imageExport";

function Footer() {
  return (
    <footer>
      <div className="footerBody">
        <div>
          <a
            className="FooterProfileImg"
            href="https://www.linkedin.com/in/jeffrey-simpson-61834560"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="FooterThumb"
              src={LinkedInIcon}
              alt="LinkedIn Profile"
              title="LinkedIn Profile"
            />
          </a>
          <a
            className="FooterProfileImg"
            href="https://github.com/jeffsimp88"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="FooterThumb"
              src={GitHubIcon}
              alt="GitHub Profile"
              title="GitHub Profile"
            />
          </a>
          <a
            className="FooterProfileImg"
            href="https://www.instagram.com/geoffer86/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="FooterThumb"
              src={InstagramIcon}
              alt="Instagram Profile"
              title="Instagram Profile"
            />
          </a>
        </div>
        <p className="footerText">Jeffrey D Simpson 2022</p>
      </div>
    </footer>
  );
}

export default Footer;
