import React from "react";
import "./AboutPage.css";
import { Link } from "react-router-dom";
import {
  InstagramIcon,
  LinkedInIcon,
  GitHubIcon,
  ProfileImage
} from "../../images/imageExport"

function AboutPage(props) {
  const { page } = props;

  return (
    <>
      <div className="AboutPageBody">
        <div className="AboutPageTextbox">
          <h1>MORE ABOUT ME</h1>
          <div className="AboutPageP1">
            <p>
              Coding is a natural field for me. It allows me to mix creativity
              with my penchant for solving puzzles to fix something broken,
              improve functionality, or create something new.{" "}
            </p>
            <p className="AboutPageBio">
              Since I was young, I’ve had a fascination with behind-the-scenes
              work. From exploring the creation of cartoons and studying
              voice-over work to seeing how television shows and full-length
              films are produced; from tinkering with old gaming systems to fix
              a broken element or revitalize it to creating my own art in a
              variety of media, I am always eager to explore the hows and whys
              of making a complex product.
            </p>
            <p>Now I am bringing that curiosity and creativity to coding.</p>
            <p>
              See examples of my work in my{" "}
              <Link className="ResumeLink" to="/portfolio">
                portfolio
              </Link>
              , view my resume, or see the creative projects I take on in my
              free time.
            </p>
          </div>
          <a
            className={`Button ${page}`}
            href="https://www.linkedin.com/in/jeffrey-simpson-61834560"
            target="_blank"
            rel="noreferrer"
          >
            Find me on LinkedIn{" "}
            <img src={LinkedInIcon} alt="LinkedIn Icon" id="LinkedInIcon" />
          </a>
          <br />
          <a
            className={`Button`}
            href="https://github.com/jeffsimp88"
            target="_blank"
            rel="noreferrer"
          >
            Find me on GitHub{" "}
            <img src={GitHubIcon} alt="GitHub Icon" id="GitHubIcon" />
          </a>
          <br />
          <a
            className={`Button ${page}`}
            href="https://www.instagram.com/geoffer86/"
            target="_blank"
            rel="noreferrer"
          >
            Find me on Instagram{" "}
            <img src={InstagramIcon} alt="Instagram Icon" id="InstagramIcon" />
          </a>
          <br />
          <Link className="Button" to="/contact">
            Contact Me
          </Link>
        </div>
        <div className="AboutPageImgBox">
          <img className="AboutPageImg" src={ProfileImage} alt="Profile" />
        </div>
      </div>
    </>
  );
}

export default AboutPage;
