import React from "react"
import { Link } from "react-router-dom";
import './NotFoundPage.css'

function NotFoundPage(){
    return(
        <div className="notFoundPageBody">
        <h1 className="notFoundPageH1">404</h1>
        <p>I'm sorry that page is not found.</p>
        <Link className="Button" to="/">Back to Homepage</Link>
        </div>
    )
}

export default NotFoundPage